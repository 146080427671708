@import "~bootswatch/dist/materia/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/materia/bootswatch";

* {
  touch-action: pan-x pan-y;
}

.container {
  max-width: 960px;
}

/*
 * Custom translucent site header
 */

.site-header {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.site-header a {
  color: #999;
  transition: ease-in-out color .15s;
}
.site-header a:hover {
  color: #fff;
  text-decoration: none;
}
/*
 * Extra utilities
 */

.flex-equal > * {
  -ms-flex: 1;
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1;
  }
}

.overflow-hidden { overflow: hidden; }

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: rgba(0,0,0,0.2);
}

.btn-social {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-social:hover {
  color: #eee;
}

.btn-social :first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  padding: 7px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.2);
}

.popover {
  padding: 5px;
}